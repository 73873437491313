import './index.css';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Menubar from '../commonComponents/sidebar';
import { Button, DatePicker, Input } from 'antd';
import axios from "axios";
import dayjs from 'dayjs';
import { toast } from "react-toastify";

export const baseurl = process.env.REACT_APP_BASE_URL;
const { TextArea } = Input;
const inputMinimumPattern = new RegExp(/^[0-9., &-]+$/);
const initialErrorMsg = {
    title: null,
    publishedOn: null,
    journal: null,
    author: null,
    affiliation: null,
    url: null,
    doi: null,
    keywords: null,
    pdfPath: null,
    txtPath: null,
    abstract: null
};

function Article() {
    const location = useLocation();
    const navigate = useNavigate();
    const [articleDetails, setArticleDetails] = useState({ title: null, publishedOn: null, journal: null, author: null, affiliation: null, url: null, doi: null, keywords: null, pdfPath: null, txtPath: null, abstract: null });
    const [errorMsg, setErrorMsg] = useState(initialErrorMsg)

    useEffect(() => {
        if (location && location.state) {
            setArticleDetails({ title: location.state.article_title, publishedOn: location.state.published_date, journal: location.state.journal, author: location.state.authors, affiliation: location.state.author_affiliation, url: location.state.url, doi: location.state.doi, keywords: location.state.keywords, pdfPath: location.state.article_file_path, txtPath: location.state.extracted_text_gdrive_folderpath, abstract: location.state.abstract });
        }
    }, [location])

    const formInputUpdate = (name, value) => {
        setArticleDetails((prev) => ({ ...prev, [name]: value ? value : null }))
        setErrorMsg((prev) => ({ ...prev, [name]: null }));
    }

    const urlPatternValidation = (URL) => {
        const pattern = new RegExp(
            "^([a-zA-Z]+:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$", // fragment locator
            "i"
        );
        return pattern.test(URL);
    };

    const inputValidation = () => {
        var message = {

        };
        if (!articleDetails.title) {
            message = { ...message, title: "Enter a title" };
        } else if (articleDetails.title && articleDetails.title.length < 3) {
            message = { ...message, title: "Title must be at least three characters long" };
        } else if ((articleDetails.title && inputMinimumPattern.test(articleDetails.title)) || (articleDetails.title && articleDetails.title.trim().length < 3) || !articleDetails.title.trim()) {
            setArticleDetails((prev) => ({ ...prev, title: prev.title.trim() }));
            message = { ...message, title: "Enter a vaild category" };
        }
        if (!articleDetails.author?.trim()) {
            message = { ...message, author: "Enter a author name" };
        }
        if (!articleDetails.publishedOn?.trim()) {
            message = { ...message, publishedOn: "Choose a article published date " };
        }
        if (!articleDetails.doi?.trim()) {
            message = { ...message, doi: "Enter a article DOI" };
        }
        if (!articleDetails.pdfPath?.trim()) {
            message = { ...message, pdfPath: "Enter a article PDF file link" };
        } else if (!urlPatternValidation(articleDetails.pdfPath)) {
            message = { ...message, pdfPath: "Enter a valid URL" };
        }
        if (articleDetails.url && !urlPatternValidation(articleDetails.url)) {
            message = { ...message, url: "Enter a valid URL" };
        }
        if (articleDetails.txtPath && !urlPatternValidation(articleDetails.txtPath)) {
            message = { ...message, txtPath: "Enter a valid URL" };
        }

        setErrorMsg((prev) => ({ ...prev, ...message }));
        console.log("errMessage", errorMsg)

        return Object.keys(message).length === 0;
    };

    const addNewArticle = () => {
        console.log("test data", articleDetails.publishedOn)
        if (inputValidation()) {
            axios
                .post(
                    `${baseurl}research_papers`,
                    {
                        "articleTitle": articleDetails.title,
                        "journal": articleDetails.journal,
                        "publishedDate": articleDetails.publishedOn ? dayjs(articleDetails.publishedOn).format('YYYY-MM-DD') : null,
                        "abstract": articleDetails.abstract,
                        "keywords": articleDetails.keywords,
                        "authors": articleDetails.author,
                        "authorAffiliation": articleDetails.affiliation,
                        "url": articleDetails.url,
                        "doi": articleDetails.doi,
                        "articleFilePath": articleDetails.pdfPath,
                        "extractedTextGdriveFolderpath": articleDetails.txtPath,
                        "summarizationStatus": "not_started"
                    },
                    {
                        headers: {
                            Authorization: JSON.parse(localStorage.getItem("token")),
                            'Content-Type': 'application/json',
                        }
                    }
                )
                .then((response) => {
                    if (response.data) {
                        toast.success("Article created successfully");
                        navigate("/dashboard")
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                        if (error.response.data.message === "Access is denied" || error.response.data.message === "Token has expired" || error.response.data.message.includes("Authorization") || error.response.status === 401) {
                            localStorage.setItem("name", null);
                            localStorage.setItem("token", null);
                            navigate('/');
                        }
                    }
                });
        }
    };

    const updateArticle = () => {
        if (inputValidation()) {
            axios
                .put(
                    `${baseurl}research_papers/${location.state?.id}`,
                    {
                        "articleTitle": articleDetails.title,
                        "journal": articleDetails.journal,
                        "publishedDate": articleDetails.publishedOn ? dayjs(articleDetails.publishedOn).format('YYYY-MM-DD') : null,
                        "abstract": articleDetails.abstract,
                        "keywords": articleDetails.keywords,
                        "authors": articleDetails.author,
                        "authorAffiliation": articleDetails.affiliation,
                        "url": articleDetails.url,
                        "doi": articleDetails.doi,
                        "articleFilePath": articleDetails.pdfPath,
                        "extractedTextGdriveFolderpath": articleDetails.txtPath,
                        "summarizationStatus": "not_started"
                    },
                    {
                        headers: {
                            Authorization: JSON.parse(localStorage.getItem("token")),
                            'Content-Type': 'application/json',
                        }
                    }
                )
                .then((response) => {
                    if (response.data) {
                        toast.success("Article updated successfully");
                        navigate("/dashboard")
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                        if (error.response.data.message === "Access is denied" || error.response.data.message === "Token has expired" || error.response.data.message.includes("Authorization") || error.response.status === 401) {
                            localStorage.setItem("name", null);
                            localStorage.setItem("token", null);
                            navigate('/');
                        }
                    }
                });
        }
    };

    return (
        <div className="article-page">
            <Menubar />
            <div className="container-md mb-4">
                <div className="article-form-title">{location.state?.id ? "Edit " : "Add New "}Article</div>
                <div className="article-form">
                    <div className="row gx-5">
                        <div className="col-md-6 mt-4">
                            <label>Article/Paper Title <span className="input-required">*</span></label>
                            <Input
                                placeholder="Article title"
                                name="title"
                                status={errorMsg.title ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.title}
                                onChange={(event) =>
                                    formInputUpdate("title", event.target.value)
                                }
                            />
                            {errorMsg.title ?
                                <span className="errorMessage">{errorMsg.title}</span>
                                : null}
                        </div>
                        <div className="col-md-6 mt-4">
                            <label>Journal</label>
                            <Input
                                placeholder="Journal"
                                name="journal"
                                status={errorMsg.journal ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.journal}
                                onChange={(event) =>
                                    formInputUpdate("journal", event.target.value)
                                }
                            />
                            {errorMsg.journal ?
                                <span className="errorMessage">{errorMsg.journal}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row gx-5">
                        <div className="col-md-6 mt-4">
                            <label>Author <span className="input-required">*</span></label>
                            <Input
                                placeholder="Author name"
                                name="author"
                                status={errorMsg.author ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.author}
                                onChange={(event) =>
                                    formInputUpdate("author", event.target.value)
                                }
                            />
                            {errorMsg.author ?
                                <span className="errorMessage">{errorMsg.author}</span>
                                : null}
                        </div>
                        <div className="col-md-6 mt-4">
                            <label>Author Affiliation</label>
                            <Input
                                placeholder="Author affiliation"
                                name="affiliation"
                                status={errorMsg.affiliation ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.affiliation}
                                onChange={(event) =>
                                    formInputUpdate("affiliation", event.target.value)
                                }
                            />
                            {errorMsg.affiliation ?
                                <span className="errorMessage">{errorMsg.affiliation}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row gx-5">
                        <div className="col-md-6 mt-4">
                            <label>Published Date <span className="input-required">*</span></label>
                            <DatePicker
                                placeholder="Published date"
                                name="publishedOn"
                                status={errorMsg.publishedOn ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.publishedOn ? dayjs(articleDetails.publishedOn) : null}
                                onChange={(date, dateString) =>
                                    formInputUpdate("publishedOn", dateString)
                                }
                            />
                            {errorMsg.publishedOn ?
                                <span className="errorMessage">{errorMsg.publishedOn}</span>
                                : null}
                        </div>

                        <div className="col-md-6 mt-4">
                            <label>URL</label>
                            <Input
                                placeholder="URL"
                                name="url"
                                status={errorMsg.url ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.url}
                                onChange={(event) =>
                                    formInputUpdate("url", event.target.value)
                                }
                            />
                            {errorMsg.url ?
                                <span className="errorMessage">{errorMsg.url}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row gx-5">
                        <div className="col-md-6 mt-4">
                            <label>DOI <span className="input-required">*</span></label>
                            <Input
                                placeholder="Digital Object Identifier"
                                name="doi"
                                status={errorMsg.doi ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.doi}
                                onChange={(event) =>
                                    formInputUpdate("doi", event.target.value)
                                }
                            />
                            {errorMsg.doi ?
                                <span className="errorMessage">{errorMsg.doi}</span>
                                : null}
                        </div>

                        <div className="col-md-6 mt-4">
                            <label>Keywords</label>
                            <Input
                                placeholder="Keywords"
                                name="keywords"
                                status={errorMsg.keywords ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.keywords}
                                onChange={(event) =>
                                    formInputUpdate("keywords", event.target.value)
                                }
                            />
                            {errorMsg.keywords ?
                                <span className="errorMessage">{errorMsg.keywords}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row gx-5">
                        <div className="col-md-6 mt-4">
                            <label>PDF File (Google Drive Link) <span className="input-required">*</span></label>
                            <Input
                                placeholder="PDF File Link"
                                name="pdfPath"
                                status={errorMsg.pdfPath ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.pdfPath}
                                onChange={(event) =>
                                    formInputUpdate("pdfPath", event.target.value)
                                }
                            />
                            {errorMsg.pdfPath ?
                                <span className="errorMessage">{errorMsg.pdfPath}</span>
                                : null}
                        </div>
                        <div className="col-md-6 mt-4">
                            <label>Text File Folder Url</label>
                            <Input
                                placeholder="Text File Folder URL"
                                name="txtPath"
                                status={errorMsg.txtPath ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.txtPath}
                                onChange={(event) =>
                                    formInputUpdate("txtPath", event.target.value)
                                }
                            />
                            {errorMsg.txtPath ?
                                <span className="errorMessage">{errorMsg.txtPath}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <label>Abstract</label>
                            <TextArea
                                rows={3}
                                maxLength={256}
                                placeholder="Abstract"
                                name="abstract"
                                status={errorMsg.abstract ? "error" : null}
                                className="search-input w-100 mt-2"
                                allowClear
                                value={articleDetails.abstract}
                                onChange={(event) =>
                                    formInputUpdate("abstract", event.target.value)
                                }
                            />
                            {errorMsg.abstract ?
                                <span className="errorMessage">{errorMsg.abstract}</span>
                                : null}
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center p-3">
                        <Link
                            to={"/dashboard"}
                            className="text-decoration-none w-auto"
                        >
                            <Button
                                className="article-clear-btn mt-3"
                            >Cancel
                            </Button>
                        </Link>
                        <Button
                            className="article-submit-btn ms-3 mt-3"
                            onClick={location.state?.id ? updateArticle : addNewArticle}
                        > {location.state?.id ? "Update" : "Submit"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Article;