import { Table, Tooltip } from "antd";
import Link_icon from "../../Assets/Image/link.png";
import pdf_white from "../../Assets/Image/pdf_white.png";
import folder_icon from "../../Assets/Image/folder-icon.png";
import cancel_icon from "../../Assets/Image/cancel_icon.svg";
export const baseurl = process.env.REACT_APP_BASE_URL;

const ArticlesTable = (props) => {
    const handlePageChange = (pagination, filters, sorter) => {
        props.tblPageUpdate({
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const columns = [
        {
            title: "Article/Paper title",
            dataIndex: "article_title",
            sorter: false,
            width: "300px",
            ellipsis: true,
            className: "ps-4",
            render: (text, record, index) => (
                <div key={index} className="d-flex flex-wrap align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.article_title ? record.article_title : null}>
                        <div className="article text-truncate">
                            {record.article_title ? record.article_title : null}
                        </div>
                    </Tooltip>
                    <div className="d-flex w-100 mb-1">
                        {record.url ? <Tooltip placement="top" overlayClassName="" title="Go to website"><a href={record.url} className="link-icon me-2" target="_blank" rel="noreferrer"><img src={Link_icon} alt={""} className="" /></a></Tooltip> : null}
                        {record.article_file_path ? <Tooltip placement="top" overlayClassName="" title="Article PDF"><a href={record.article_file_path} target="_blank" rel="noreferrer" className="pdf-white-icon me-2"><img src={pdf_white} alt={""} className="" /></a></Tooltip> : null}
                        {record.extracted_text_gdrive_folderpath ?
                            <Tooltip placement="top" title="Text file used Summary"><div className={"use-text"} >Use text</div></Tooltip>
                            : null}
                        {record.summarization_status === "completed" ?
                            <Tooltip placement="top" title="Summary files view"><a href={record.summary_file_path} target="_blank" rel="noreferrer" className="cursorP ms-2"><img src={folder_icon} alt={""} className="" /></a></Tooltip>
                            : null}
                        {record.summarization_status === "failed" ?
                            (record.error_message && record.error_message !== "") ?
                                <Tooltip placement="top" title={record.error_message}><img src={cancel_icon} alt={""} className="ms-2" /></Tooltip>
                                : <img src={cancel_icon} alt={""} className="ms-2" />
                            : null}
                    </div>
                </div>
            ),
        },
        {
            title: "Year",
            dataIndex: "year",
            sorter: false,
            width: "80px",
            align: "center",
            ellipsis: true,
            className: "px-0",
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center justify-content-center">
                    <div className="d-flex">
                        {record.published_date ? new Date(record.published_date).getFullYear() : null}
                    </div>
                </div>
            ),
        },
        {
            title: "Journal",
            dataIndex: "journal",
            sorter: false,
            width: "150px",
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.journal ? record.journal : null}>
                        <div className="text-truncate">
                            {record.journal ? record.journal : null}
                        </div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Author(s)",
            dataIndex: "authors",
            width: "200px",
            sorter: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="text-truncate">
                    {record.authors ?
                        typeof (record.authors) === "string" ?
                            <Tooltip placement="top" overlayClassName="" title={record.authors}>
                                <div className="text-truncate">{record.authors}</div>
                            </Tooltip>
                            :
                            record.authors.map((data, index) =>
                                <Tooltip placement="top" overlayClassName="" title={data}>
                                    <div className="text-truncate" key={index}>{data}</div>
                                </Tooltip>
                            )
                        : null}
                </div>
            ),
        },
        {
            title: "Author affiliation",
            dataIndex: "author_affiliation",
            width: "250px",
            sorter: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.author_affiliation ? record.author_affiliation : null}>
                        <div className="text-truncate">
                            {record.author_affiliation ? record.author_affiliation : null}
                        </div>
                    </Tooltip>
                </div>
            ),
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                onChange={handlePageChange}
                scroll={{ x: 1000, y: 640 }}
                rowKey={(record) => record.id}
                className="mt-5 summarize-table"
                rowClassName={(record, index) => (record.summarization_status === "in_progress" ? "in-progress-row" : record.summarization_status === "completed" ? "completed-row" : record.summarization_status === "failed" ? "failed-row" : "not-started-row")}
                pagination={{
                    current: props.tblPageDetails.current,
                    pageSize: props.tblPageDetails.pageSize,
                    total: props.tblPageDetails.totalElements,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [10, 20, 50],
                }}
            />
        </>
    );
};
export default ArticlesTable;