import './App.css';
import { ConfigProvider } from 'antd';
import Router from "./components/router";
import { GoogleOAuthProvider } from '@react-oauth/google';
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
    return (
        <div className="gubbi-labs-app">
            <GoogleOAuthProvider clientId={googleClientId}>
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: "'Alegreya',Georgia,Times,Times New Roman,Serif",
                        },
                    }}
                >
                    <Router />
                </ConfigProvider>
            </GoogleOAuthProvider>
        </div>
    );
}

export default App;
