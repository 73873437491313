import "./index.css";
import Logo from "../../Assets/Image/logo.png";
import Google from "../../Assets/Image/google.png";
import { useEffect } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
export const baseurl = process.env.REACT_APP_BASE_URL;

function Login() {

    const navigate = useNavigate();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("token"))) {
            navigate("/dashboard")
        }

        //eslint-disable-next-line
    }, [])

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            if (codeResponse && codeResponse.access_token) {
                axios
                    .get(baseurl + 'login/google', {
                        headers: {
                            Authorization: `Bearer ${codeResponse.access_token}`,
                            tokenType: codeResponse.token_type,
                            accessToken: codeResponse.access_token,
                            expiryTime: codeResponse.expires_in,
                            requestType: "google-login",
                            'Content-Type': 'application/json'
                        }
                    })
                    .then((res) => {
                        if (res.data.status === false){
                            toast.error(res.data.message);
                        }else if (res.data.name) {
                            localStorage.setItem("name", JSON.stringify(res.data.name));
                            localStorage.setItem("token", JSON.stringify(res.data.jwt ? "Bearer "+ res.data.jwt : null));
                            navigate('/dashboard');
                        } else {
                            toast.error("Something went wrong");
                        }
                    })
                    .catch((err) => {
                        if(err.response.data && err.response.data.message){
                            toast.error(err.response.data.message)
                        }else{
                            toast.error("Something went wrong")
                        }
                    });
            } else {
                toast.error("Something went wrong")
            }
        },
        onError: (error) => {
            toast.error("Something went wrong")
        }
    });

    return (
        <div className="login row m-0 p-0">
            <div className="left-layout col-12 col-md-6">
                <div className="logo">
                    <img src={Logo} alt={"logo"} className="logo-img" />
                </div>
            </div>
            <div className="right-layout col-12 col-md-6">
                <div className="box">
                    <div className="title">Sign In</div>
                    <button className="glogin-btn" onClick={() => login()}>
                        <div className="glogin-icon"><img src={Google} alt={"google"} className="google-img" /></div>
                        <div className="glogin-text">Continue with Google</div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Login;
