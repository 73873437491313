import './index.css';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from "antd";
import { useEffect, useState } from "react";
import Menubar from "../commonComponents/sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import ArticlesTable from './articlesTable';
export const baseurl = process.env.REACT_APP_BASE_URL;

function RunScrapperScript() {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [rssFeed, setRssFeed] = useState({ value: null, errorMsg: null, status: "not_yet" });
    const [tblPageDetails, setTblPageDetails] = useState({
        current: 1,
        pageSize: 10,
        sortCol: "created_by,desc",
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        if (!(JSON.parse(localStorage.getItem("token")))) {
            localStorage.setItem("name", null);
            localStorage.setItem("token", null);
            navigate('/');
        }

        // eslint-disable-next-line
    }, [])

    const tblPageUpdate = (data) => {
        setTblPageDetails((prev) => ({ ...prev, ...data }));
    };

    const rssFeedUpdate = (event) => {
        if (rssFeed.status !== "in_progress") {
            setRssFeed((prev) => ({ ...prev, value: event.target.value, errorMsg: null }))
        }
    }

    const urlPatternValidation = (URL) => {
        const pattern = new RegExp(
            "^([a-zA-Z]+:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$", // fragment locator
            "i"
        );
        return pattern.test(URL);
    };

    const runRssFeed = () => {
        if (!rssFeed.value) {
            setRssFeed((prev) => ({ ...prev, errorMsg: "Enter a RSS URL" }))
            return false
        } else if (!rssFeed.value?.trim() || !urlPatternValidation(rssFeed.value)) {
            setRssFeed((prev) => ({ ...prev, errorMsg: "Enter a vaild RSS URL" }))
            return false
        }
        setRssFeed((prev) => ({ ...prev, status: "in_progress" }))

        axios
            .get(baseurl + 'summarize_feed', {
                params: {
                    "rssurl": rssFeed.value
                },
                headers: {
                    Authorization: JSON.parse(localStorage.getItem("token")),
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            })
            .then((res) => {
                if (res.data && res.data instanceof Array) {
                    setList(res.data)
                } else if (res.data && res.data.message) {
                    toast.success(res.data.message)
                }
                setRssFeed((prev) => ({ ...prev, status: "completed" }))
            })
            .catch((err) => {
                if (err.response.data && err.response.data.message) {
                    toast.error(err.response.data.message)
                    if (err.response.data.message === "Access is denied" || err.response.data.message === "Token has expired" || err.response.data.message.includes("Authorization") || err.response.status === 401) {
                        localStorage.setItem("name", null);
                        localStorage.setItem("token", null);
                        navigate('/');
                    }
                } else {
                    toast.error("Something went wrong")
                }
                setRssFeed((prev) => ({ ...prev, status: "completed" }))
            });
    }

    return (
        <div className="rss">
            <Menubar />
            <div className={"rss-body container-md mb-3" + (list?.length === 0 ? " search-box" : "")}>
                <div className="w-100">
                    {list?.length === 0 ?
                        <div className="pe-md-5">
                            <div className="rss-title mb-2">
                                RSS feed's URL
                            </div>
                            <div className="rss-desc mb-4">
                                Run the RSS feed URL to get article's data
                            </div>
                        </div>
                        : null}
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 mt-3">
                            <Input
                                placeholder="RSS URL"
                                name="title"
                                status={rssFeed.errorMsg ? "error" : null}
                                className="search-input w-100"
                                allowClear
                                value={rssFeed.value}
                                onChange={rssFeedUpdate}
                            />
                            {rssFeed.errorMsg ?
                                <span className="errorMessage">{rssFeed.errorMsg}</span>
                                : null}
                        </div>
                        <div className="col-md-2 mt-3 d-flex justify-content-center align-items-start">
                            <Button
                                loading={rssFeed.status === "in_progress"}
                                onClick={runRssFeed}
                                className="run-btn"
                            >
                                Run
                            </Button>
                        </div>
                    </div>

                    {list?.length > 0 ?
                        <ArticlesTable
                            data={list}
                            tblPageDetails={tblPageDetails}
                            tblPageUpdate={tblPageUpdate}
                        />
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default RunScrapperScript;