import { Table, Checkbox, Modal, Tooltip } from "antd";
import Link_icon from "../../Assets/Image/link.png";
import pdf_white from "../../Assets/Image/pdf_white.png";
import folder_icon from "../../Assets/Image/folder-icon.png";
import progress_icon from "../../Assets/Image/inProgress.png";
import completed_icon from "../../Assets/Image/completed.png";
import cancel_icon from "../../Assets/Image/cancel_icon.svg";
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { toast } from "react-toastify";
export const baseurl = process.env.REACT_APP_BASE_URL;
const { confirm } = Modal;

const SummarizeTable = (props) => {
    const handlePageChange = (pagination, filters, sorter) => {
        props.tblPageUpdate({
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const showDeleteConfirm = (articleId) => {
        confirm({
            centered: true,
            title: 'Are you sure to delete this Article?',
            icon: <ExclamationCircleFilled />,
            className: 'article-confirm-modal',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                props.deleteArticle(articleId);
            },
        });
    };

    const selectChanges = (event, record) => {
        if (record.article_file_path) {
            props.onSelectChange(record.id, event.target.checked)
        } else {
            toast.error("Article file not available")
        }
    }

    const columns = [
        {
            title: <Checkbox className="select-all" onChange={(event) => props.onSelectAllChange("selectAll", event.target.checked)} disabled={!props.selectedAllKeys.length > 0} checked={props.selectedAllKeys.length > 0 && props.selectedAllKeys.length === props.selectedRowKeys.length} />,
            dataIndex: "select",
            sorter: false,
            align: "center",
            width: "50px",
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center justify-content-center">
                    {["not_started", "completed", "failed"].includes(record.summarization_status) ?
                        <div className={"d-flex align-items-center "}>
                            <Checkbox onChange={(event) => selectChanges(event, record)} disabled={!["not_started", "completed", "failed"].includes(record.summarization_status)} checked={props.selectedRowKeys.includes(record.id)} />
                        </div>
                        : record.summarization_status === "in_progress" ?
                            <div className="in-progress-box">
                                <img src={progress_icon} alt={""} className="progress-icon" />
                            </div>
                            : record.summarization_status === "completed" ?
                                <div className="completed-box">
                                    <img src={completed_icon} alt={""} className="completed-icon" />
                                </div>
                                : null}
                </div>
            ),
        },
        {
            title: "Article/Paper title",
            dataIndex: "article_title",
            sorter: false,
            width: "300px",
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex flex-wrap align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.article_title ? record.article_title : null}>
                        <div className="article text-truncate">
                            {record.article_title ? record.article_title : null}
                        </div>
                    </Tooltip>
                    <div className="d-flex w-100 mb-1">
                        {record.url ? <Tooltip placement="top" overlayClassName="" title="Go to website"><a href={record.url} className="link-icon me-2" target="_blank" rel="noreferrer"><img src={Link_icon} alt={""} className="" /></a></Tooltip> : null}
                        {record.article_file_path ? <Tooltip placement="top" overlayClassName="" title="Article PDF"><a href={record.article_file_path} target="_blank" rel="noreferrer" className="pdf-white-icon me-2"><img src={pdf_white} alt={""} className="" /></a></Tooltip> : null}
                        {record.extracted_text_gdrive_folderpath ?
                            <Tooltip placement="top" title="Text file used Summary"><div className={"use-text" + (props.useTextIds.includes(record.id) ? " active" : "")} onClick={() => props.useTextUpdate(record.id)}>Use text</div></Tooltip>
                            : null}
                        {record.summarization_status === "completed" ?
                            <Tooltip placement="top" title="Summary files view"><a href={record.summary_file_path} target="_blank" rel="noreferrer" className="cursorP ms-2"><img src={folder_icon} alt={""} className="" /></a></Tooltip>
                            : null}
                        {record.summarization_status === "failed" ?
                            (record.error_message && record.error_message !== "") ?
                                <Tooltip placement="top" title={record.error_message}><img src={cancel_icon} alt={""} className="ms-2" /></Tooltip>
                                : <img src={cancel_icon} alt={""} className="ms-2" />
                            : null}
                    </div>
                </div>
            ),
        },
        {
            title: "Year",
            dataIndex: "year",
            sorter: false,
            width: "80px",
            align: "center",
            ellipsis: true,
            className: "px-0",
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center justify-content-center">
                    <div className="d-flex">
                        {record.published_date ? new Date(record.published_date).getFullYear() : null}
                    </div>
                </div>
            ),
        },
        {
            title: "Journal",
            dataIndex: "journal",
            sorter: false,
            width: "150px",
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.journal ? record.journal : null}>
                        <div className="text-truncate">
                            {record.journal ? record.journal : null}
                        </div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Author(s)",
            dataIndex: "authors",
            width: "200px",
            sorter: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="text-truncate">
                    {record.authors ?
                        typeof (record.authors) === "string" ?
                            <Tooltip placement="top" overlayClassName="" title={record.authors}>
                                <div className="text-truncate">{record.authors}</div>
                            </Tooltip>
                            :
                            record.authors.map((data, index) =>
                                <Tooltip placement="top" overlayClassName="" title={data}>
                                    <div className="text-truncate" key={index}>{data}</div>
                                </Tooltip>
                            )
                        : null}
                </div>
            ),
        },
        {
            title: "Author affiliation",
            dataIndex: "author_affiliation",
            width: "250px",
            sorter: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center">
                    <Tooltip placement="top" overlayClassName="" title={record.author_affiliation ? record.author_affiliation : null}>
                        <div className="text-truncate">
                            {record.author_affiliation ? record.author_affiliation : null}
                        </div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "Actions",
            dataIndex: "action",
            width: "80px",
            sorter: false,
            ellipsis: true,
            render: (text, record, index) => (
                <div key={index} className="d-flex align-items-center">
                    <Tooltip placement="top" title={"Edit Article"}><EditOutlined className='actionIcon' onClick={() => props.modifyArticle(record)} /></Tooltip>
                    <Tooltip placement="top" title={"Delete Article"}><DeleteOutlined className='actionIcon' onClick={() => showDeleteConfirm(record.id)} /></Tooltip>
                </div>
            ),
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                onChange={handlePageChange}
                scroll={{ x: 1000, y: 640 }}
                rowKey={(record) => record.id}
                className="mt-4 summarize-table"
                rowClassName={(record, index) => (record.summarization_status === "in_progress" ? "in-progress-row" : record.summarization_status === "completed" ? "completed-row" : record.summarization_status === "failed" ? "failed-row" : "not-started-row")}
                pagination={{
                    current: props.tblPageDetails.current,
                    pageSize: props.tblPageDetails.pageSize,
                    total: props.tblPageDetails.totalElements,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [10, 20, 50],
                }}
            />
        </>
    );
};
export default SummarizeTable;
