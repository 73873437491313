import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Button, Modal, DatePicker } from "antd";
import { useEffect, useState } from "react";
import Menubar from "../commonComponents/sidebar";
import SummarizeTable from './summarizeTable';
import SummarizationListConfirmModal from './summarizeConfirmModal';
import axios from "axios";
import dayjs from 'dayjs';
import { toast } from "react-toastify";
export const baseurl = process.env.REACT_APP_BASE_URL;
const { Search } = Input;

const numberRegEx = /^[0-9\b]+$/

function Dashboard() {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [pageUpdate, setPageUpdate] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAllKeys, setSelectedAllKeys] = useState([]);
    const [filterOptions, setFilterOptions] = useState({ title: null, year: null, journal: null, author: null, affiliation: null, triggeredBy: null, triggeredOn: null });
    const [summarizeModal, setSummarizeModal] = useState({ status: false, confirmList: [] });
    const [useTextIds, setUseTextIds] = useState([]);
    const [tblPageDetails, setTblPageDetails] = useState({
        current: 1,
        pageSize: 10,
        sortCol: "created_by,desc",
        totalElements: 0,
        totalPages: 0,
    });

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("token"))) {
            axios
                .get(baseurl + 'research_papers', {
                    params: {
                        "page": tblPageDetails.current,
                        "perPage": tblPageDetails.pageSize,
                        "articleTitle": filterOptions.title && filterOptions.title.trim(),
                        "journal": filterOptions.journal && filterOptions.journal.trim(),
                        "year": filterOptions.year && filterOptions.year.length > 3 ? filterOptions.year : null,
                        "author": filterOptions.author && filterOptions.author.trim(),
                        "authorAffiliation": filterOptions.affiliation && filterOptions.affiliation.trim(),
                        "triggeredBy": filterOptions.triggeredBy && filterOptions.triggeredBy.trim(),
                        "triggeredOn": filterOptions.triggeredOn
                    },
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem("token")),
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    }
                })
                .then((res) => {
                    if (res.data.articles && res.data.articles instanceof Array) {
                        let currentPage = {}
                        if (tblPageDetails.current === 1 || tblPageDetails.current <= res.data.totalPages) {
                            setList(res.data.articles)
                            var listId = []
                            res.data.articles.filter(obj => obj.summarization_status !== "in_progress" && obj.article_file_path ? listId.push(obj.id) : null);
                            setSelectedAllKeys(listId)
                            setSelectedRowKeys([])
                        } else {
                            currentPage = { current: 1 }
                        }
                        setTblPageDetails((prev) => ({ ...prev, totalElements: res.data.totalElements, totalPages: res.data.totalPages, ...currentPage }))
                    } else {
                        toast.error("Something went wrong");
                    }
                })
                .catch((err) => {
                    if (err.response.data && err.response.data.message) {
                        toast.error(err.response.data.message)
                        if (err.response.data.message === "Access is denied" || err.response.data.message === "Token has expired" || err.response.data.message.includes("Authorization") || err.response.status === 401) {
                            localStorage.setItem("name", null);
                            localStorage.setItem("token", null);
                            navigate('/');
                        }
                    } else {
                        toast.error("Something went wrong")
                    }
                });
        } else {
            localStorage.setItem("name", null);
            localStorage.setItem("token", null);
            navigate('/');
        }

        // eslint-disable-next-line
    }, [pageUpdate, filterOptions, tblPageDetails.current, tblPageDetails.pageSize])

    const filterInputUpdate = (name, value) => {
        if (name === "year") {
            if (numberRegEx.test(value) || !value) {
                setFilterOptions((prev) => ({ ...prev, [name]: value ? value : null }))
            }
        } else {
            setFilterOptions((prev) => ({ ...prev, [name]: value ? value : null }))
        }
    }

    const onSelectAllChange = (name, status) => {
        if (status) {
            setSelectedRowKeys(selectedAllKeys)
        } else {
            setSelectedRowKeys([]);
        }
    }

    const onSelectChange = (listId, status) => {
        if (status) {
            setSelectedRowKeys((prev) => ([...prev, listId]))
        } else {
            setSelectedRowKeys((current) => current.filter((ids) => ids !== listId));
        }
    };

    const tblPageUpdate = (data) => {
        setTblPageDetails((prev) => ({ ...prev, ...data }));
    };

    const confirmModalOpen = () => {
        const confirmSummarizeList = list.filter((data) => selectedRowKeys.includes(data.id));
        setSummarizeModal((prev) => ({ ...prev, confirmList: confirmSummarizeList, status: true }))
    }

    const confirmModalClose = (page = "") => {
        setSummarizeModal((prev) => ({ ...prev, confirmList: [], status: false }))
        if (page === "pageUpdate") {
            setPageUpdate(!pageUpdate)
        }
    }

    const clearFilterText = () => {
        setFilterOptions((prev) => ({ ...prev, title: null, year: null, journal: null, author: null, affiliation: null, triggeredBy: null, triggeredOn: null }))
    }

    const useTextUpdate = (id) => {
        if (useTextIds.includes(id)) {
            const articleId = useTextIds.filter((item) => item !== id)
            setUseTextIds(articleId)
        } else {
            setUseTextIds((prev) => ([...prev, id]))
        }
    }

    const modifyArticle = (data) => {
        navigate('/article', { state: data });
    }

    const deleteArticle = (articleId) => {
        axios
            .delete(`${baseurl}research_papers/${articleId}`, {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem("token")),
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            })
            .then((response) => {
                if (response.data) {
                    if (list && list.length === 1) {
                        setTblPageDetails((prev) => ({ ...prev, current: prev.current > 1 ? prev.current - 1 : 1 }));
                    }
                    setPageUpdate(!pageUpdate);
                    toast.success("Article deleted successfully");
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    if (error.response.data.message === "Access is denied") {
                        toast.error(error.response.data.message);
                        navigate("/")
                    }
                }
            });
    };

    return (
        <div className="dashboard">
            {summarizeModal.status ?
                <Modal
                    centered
                    title={<div className='d-flex'>Are you sure you want to summarize below <div className='mx-2 count'> {summarizeModal.confirmList.length} </div> files ?</div>}
                    className="summarize-confirm-modal"
                    maskClosable={true}
                    open={summarizeModal}
                    footer={[]}
                    onCancel={confirmModalClose}
                    width={692}
                >
                    <SummarizationListConfirmModal
                        selectedRowKeys={selectedRowKeys}
                        confirmList={summarizeModal.confirmList}
                        confirmModalClose={confirmModalClose}
                        useTextIds={useTextIds}
                    />
                </Modal>
                : null}
            <Menubar />
            <div className="summarize container-md mb-4">
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <Link
                            to={"/rss"}
                            className="text-decoration-none"
                        >
                            <Button
                                className="rss-btn me-3"
                            >Run Scrapper Script
                            </Button>
                        </Link>
                        <Link
                            to={"/article"}
                            className="text-decoration-none"
                        >
                            <Button
                                className="add-btn me-3"
                            >Add New Article
                            </Button>
                        </Link>
                        <Button
                            disabled={!selectedRowKeys.length > 0}
                            className="summarize-btn"
                            onClick={confirmModalOpen}
                        >Summarize
                        </Button>
                    </div>
                </div>
                <div className="ques-filter row">
                    <div className="col-md-4 mt-4">
                        <label>Article/Paper title</label>
                        <Search
                            placeholder="Search a paper title"
                            name="title"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.title}
                            onChange={(event) =>
                                filterInputUpdate("title", event.target.value)
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-4">
                        <label>Journal</label>
                        <Search
                            placeholder="Search a journal"
                            name="journal"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.journal}
                            onChange={(event) =>
                                filterInputUpdate("journal", event.target.value)
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-4">
                        <label>Author</label>
                        <Search
                            placeholder="Search an author name"
                            name="author"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.author}
                            onChange={(event) =>
                                filterInputUpdate("author", event.target.value)
                            }
                        />
                    </div>
                    <div className="col-md-2 mt-4">
                        <label>Year</label>
                        <Search
                            placeholder="Search a year"
                            name="year"
                            minLength={4}
                            maxLength={4}
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.year}
                            onChange={(event) =>
                                filterInputUpdate("year", event.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="ques-filter row">
                    <div className="col-md-4 mt-4">
                        <label>Author affiliation</label>
                        <Search
                            placeholder="Search an author affiliation"
                            name="affiliation"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.affiliation}
                            onChange={(event) =>
                                filterInputUpdate("affiliation", event.target.value)
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-4">
                        <label>Trigger on</label>
                        <DatePicker
                            placeholder="Select trigger on date"
                            name="triggeredOn"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.triggeredOn ? dayjs(filterOptions.triggeredOn) : null}
                            onChange={(date, dateString) =>
                                filterInputUpdate("triggeredOn", dateString)
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-4">
                        <label>Trigger by</label>
                        <Search
                            placeholder="Search an trigger name"
                            name="triggeredBy"
                            className="search-input w-100 mt-2"
                            allowClear
                            value={filterOptions.triggeredBy}
                            onChange={(event) =>
                                filterInputUpdate("triggeredBy", event.target.value)
                            }
                        />
                    </div>
                    <div className="col-md-2 mt-4 d-flex align-items-end">
                        <Button
                            className="clear-btn"
                            onClick={clearFilterText}
                        >Clear filter
                        </Button>
                    </div>
                </div>
                <SummarizeTable
                    data={list}
                    selectedRowKeys={selectedRowKeys}
                    selectedAllKeys={selectedAllKeys}
                    onSelectAllChange={onSelectAllChange}
                    onSelectChange={onSelectChange}
                    tblPageDetails={tblPageDetails}
                    tblPageUpdate={tblPageUpdate}
                    useTextUpdate={useTextUpdate}
                    useTextIds={useTextIds}
                    modifyArticle={modifyArticle}
                    deleteArticle={deleteArticle}
                />
            </div>
        </div>
    );
}

export default Dashboard;