import { Dropdown } from "antd";
import { LogoutOutlined } from '@ant-design/icons';
import downArrow from "../../Assets/Image/downArrow.png";
import Logo from "../../Assets/Image/logo.png";
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Menubar() {

    const navigate = useNavigate();
    const location = useLocation()

    const logoutFun = () => {
        localStorage.setItem("name", null);
        localStorage.setItem("token", null);
        navigate('/');
    }

    const items = [
        {
            label: <div className="d-flex align-items-center cursorP" onClick={logoutFun}><LogoutOutlined className='sidebar-icon' /> <span className="ms-2">Logout</span> </div>,
            key: '0',
        }
    ];

    return (
        <div className="menubar">
            <div className="container-md d-flex align-items-center justify-content-between">
                <div className="menubar-logo">
                    <img src={Logo} alt={"logo"} className="menubar-logo-img" />
                </div>

                <div className="d-flex">
                    {location?.pathname !== "/dashboard" ?
                        <Link
                            to={"/dashboard"}
                            className="text-decoration-none menu me-5"
                        >
                            Dashboard
                        </Link>
                        : null}
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        overlayClassName="user-profile-menu"
                    >
                        <div className="menubar-user cursorP" onClick={(e) => e.preventDefault()}>
                            <div className="user-name">{JSON.parse(localStorage.getItem("name"))}</div>
                            <img src={downArrow} alt={""} className="down-arrow ms-3" />
                        </div>
                    </Dropdown>
                </div>

            </div>
        </div>
    );
}

export default Menubar;