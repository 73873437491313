import { Button } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
export const baseurl = process.env.REACT_APP_BASE_URL;

export const getFirstLetterCapitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

function SummarizationListConfirmModal(props) {

    const summarizeConfirm = () => {
        const articlesToSummarize = props.confirmList.map((data) => ({ "articleId": data.id, "extractedTextGdriveFolderpath": props.useTextIds.includes(data.id) ? data.extracted_text_gdrive_folderpath : null }) );
        axios
            .post(baseurl + 'summarize_articles', { 
                "articlesToSummarize": articlesToSummarize,
                "summarizationTriggeredBy": JSON.parse(localStorage.getItem("name"))
            } , {
                headers: {
                    Authorization: JSON.parse(localStorage.getItem("token")),
                    'Content-Type': 'application/json',
                }
            })
            .then((res) => {
                toast.success(getFirstLetterCapitalize(res.data.message))
                props.confirmModalClose("pageUpdate");
            })
            .catch((err) => {
                if(err.response.data && err.response.data.message){
                    toast.error(err.response.data.message)
                }else{
                    toast.error("Something went wrong")
                }
            });
    }

    return (
        <div className="summarize-confirm">
            <ul className="summarize-list my-4">
                {props.confirmList.map((data, index) =>
                    <li key={index} className="my-2">{data.article_title}</li>
                )}
            </ul>
            <div className="d-flex align-items-center justify-content-center">
                <Button
                    disabled={!props.confirmList.length > 0}
                    className="summarize-btn"
                    onClick={summarizeConfirm}
                >Summarize
                </Button>
            </div>
        </div>
    )
}

export default SummarizationListConfirmModal;