import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from '../login/index';
import Dashboard from "../dashboard/index";
import Article from "../article";
import RunScrapperScript from "../runScrapperScript";

function Router() {
    return (
        <div className="trident-web">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/article" element={<Article />} />
                    <Route path="/rss" element={<RunScrapperScript />} />
                    <Route path="*" element={<Navigate to='/dashboard' />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Router;